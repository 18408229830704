import {api, graph2, graph_vars, test, test2} from '../utils/shared'

export const bookings = async(token, fields, vars=[]) => {

    let vparams = ""
    let qparams = ""
    let j_params = {}
    if(vars.length != 0){
        let v = []
        let p = []
        for(let x of vars){
            v.push(x["param_var_name"] + ": " + x["param_type"]);
            p.push(x["param_name"] + ": " + x["param_var_name"]);
            j_params[x["param_name"]] = x["value"]
        }
        vparams = `(${v.join(" ")})`
        qparams = `(${p.join(" ")})`
    }



    let q = `query ${vparams} {
            bookings ${qparams}
            {
                result
                    {
                        ${fields}
                    }
        error {
			... on Unexpected_Error {
				message
			}
		}
    }
    }`

    let x = await graph_vars(q, j_params, token)
    return x
}

export const booking = async(booking_id, fields, token) => {
    let q = `{booking(id: ${booking_id}) {
		result {
            ${fields}
		}
		error {
			... on Unexpected_Error {
				message
			}
		}
	}}`

    let x = await graph2(q, token)
    return x
}

export const booking_package = async(booking_id, fields, token) => {
    let q = `{
        booking_package(booking_id: ${booking_id})
        {
        result
        {
			${fields}
        }
        error {
			... on Unexpected_Error {
				message
			}
		}
    }
    }`
    let x = await graph2(q, token)
    return x
}

export const booking_room = async(product_id, booking_id, fields, token) => {
    let q = `{
        booking_room(product_id: ${product_id}, booking_id: ${booking_id})
        {
        result
        {
			${fields}
        }
        error {
			... on Unexpected_Error {
				message
			}
		}
    }
    }`
    let x = await graph2(q, token)
    return x
}

export const booking_rooms = async(booking_id, fields, token) => {
    let q = `{
        booking_rooms(booking_id: ${booking_id})
        {
        result
        {
			${fields}
        }
        error {
			... on Unexpected_Error {
				message
			}
		}
    }
    }`
    let x = await graph2(q, token)
    return x
}

export const booking_flight = async(product_id, booking_id, fields, token) => {
    let q = `{
        booking_flight(product_id: ${product_id}, booking_id: ${booking_id})
        {
        result
        {
			${fields}
        }
        error {
			... on Unexpected_Error {
				message
			}
		}
    }
    }`
    let x = await graph2(q, token)
    return x
}

export const booking_flight_extras = async(booking_id, fields, token) => {
    let q = `{
        booking_flight_extras(booking_id: ${booking_id})
        {
        result
        {
			${fields}
        }
        error {
			... on Unexpected_Error {
				message
			}
		}
    }
    }`
    let x = await graph2(q, token)
    return x
}

export const booking_transfer = async(product_id, booking_id, fields, token) => {
    let q = `{
        booking_transfer(product_id: ${product_id}, booking_id: ${booking_id})
        {
        result
        {
			${fields}
        }
        error {
			... on Unexpected_Error {
				message
			}
		}
    }
    }`
    let x = await graph2(q, token)
    return x
}

export const booking_airport_parking = async(product_id, booking_id, fields, token) => {
    let q = `{
        booking_airport_parking(product_id: ${product_id}, booking_id: ${booking_id})
        {
        result
        {
			${fields}
        }
        error {
			... on Unexpected_Error {
				message
			}
		}
    }
    }`
    let x = await graph2(q, token)
    return x
}

export const booking_airport_accommodation = async(product_id, booking_id, fields, token) => {
    let q = `{
        booking_airport_accommodation(product_id: ${product_id}, booking_id: ${booking_id})
        {
        result
        {
			${fields}
        }
        error {
			... on Unexpected_Error {
				message
			}
		}
    }
    }`
    let x = await graph2(q, token)
    return x
}

export const booking_lounge = async(product_id, booking_id, fields, token) => {
    let q = `{
        booking_lounge(product_id: ${product_id}, booking_id: ${booking_id})
        {
        result
        {
			${fields}
        }
        error {
			... on Unexpected_Error {
				message
			}
		}
    }
    }`
    let x = await graph2(q, token)
    return x
}

export const booking_payments = async(booking_id, fields, token) => {
    let q = `{
        booking_payments(booking_id: ${booking_id})
        {
        result
        {
			${fields}
        }
        error {
			... on Unexpected_Error {
				message
			}
		}
    }
    }`
    let x = await graph2(q, token)
    return x
}

export const booking_payment_plan = async(booking_id, fields, token) => {
    let q = `{
        booking_payment_plan(booking_id: ${booking_id})
        {
        result
        {
			${fields}
        }
        error {
			... on Unexpected_Error {
				message
			}
		}
    }
    }`
    let x = await graph2(q, token)
    return x
}

export const add_booking_note = async(booking_id, note, fields, token) => {
    let q = `mutation ($booking_id: Int!, $note: String!) {
            booking_add_note(booking_id: $booking_id, note: $note)
            {
                result
                {
                    ${fields}
                }
            error {
                ... on Unexpected_Error {
                    message
                }
                ... on Access_Error {
                    message 
                }
            }
        }
        }`
    let x = await graph_vars(q, {"booking_id": booking_id, "note": note}, token)
    return x
}

export const booking_update_confirmed = async(booking_id, confirmed, token) => {
    let q = `mutation ($booking_id: Int!, $confirmed: Int!) {
        booking_update_confirmed(booking_id: $booking_id, confirmed: $confirmed)
            {
                result
                {
                    confirmed
                }
            error {
                ... on Unexpected_Error {
                    message
                }
                ... on Access_Error {
                    message 
                }
            }
        }
        }`
    let x = await graph_vars(q, {"booking_id": booking_id, "confirmed": confirmed}, token)
    return x
}

export const booking_update_hidden = async(booking_id, hidden, token) => {
    let q = `mutation ($booking_id: Int!, $hidden: Int!) {
        booking_update_hidden(booking_id: $booking_id, hidden: $hidden)
            {
                result
                {
                    hidden
                }
            error {
                ... on Unexpected_Error {
                    message
                }
                ... on Access_Error {
                    message 
                }
            }
        }
        }`
    let x = await graph_vars(q, {"booking_id": booking_id, "hidden": hidden}, token)
    return x
}



export const booking_logs = async(session_reference, booking_id, token, file_name) => {

    api('/booking_logs/' +  session_reference + '/' + booking_id, null, 'GET',(response) => {
            if(response != null){
                response.blob().then((data) => {
                    var a = document.createElement('a');
                    a.href = window.URL.createObjectURL(data);
                    a.download = file_name;
                    a.click();
                })
            }
    }, null, token)
}

export const booking_count_total = async(date_from=null, date_to=null, prod_type=null, token) => {
    return new Promise(async (resolve, reject) => {

        const pd = {
            "date_from": date_from,
            "date_to": date_to,
            "type": prod_type
        }

        await api(
            "/booking_total_count/", pd,
            "POST",
            (d) => {
                d.json().then((result) => {
                    resolve(result);
                });
            },
            (e) => console.log(e), token, process.env.REACT_APP_STATS_URL
        );
    });
}

export const booking_count_by_date = async(duration=null, date_from=null, date_to=null, adults=null, children=null, prod_type=null, token) => {
    return new Promise(async (resolve, reject) => {

        const pd = {
            "duration": duration,
            "date_from": date_from,
            "date_to": date_to,
            "adults": adults,
            "children": children,
            "type": prod_type
        }

        await api(
            "/booking_count_by_date/", pd,
            "POST",
            (d) => {
                d.json().then((result) => {
                    resolve(result);
                });
            },
            (e) => console.log(e), token, process.env.REACT_APP_STATS_URL
        );
    });
}

export const booking_cost = async(duration=null, date_from=null, date_to=null, adults=null, children=null, prod_type=null, token) => {
    return new Promise(async (resolve, reject) => {

        const pd = {
            "duration": duration,
            "date_from": date_from,
            "date_to": date_to,
            "adults": adults,
            "children": children,
            "type": prod_type
        }

        await api(
            "/booking_cost/", pd,
            "POST",
            (d) => {
                d.json().then((result) => {
                    resolve(result);
                });
            },
            (e) => console.log(e), token, process.env.REACT_APP_STATS_URL
        );
    });
}

export const booking_avg_cost = async(duration=null, date_from=null, date_to=null, adults=null, children=null, prod_type=null, token) => {
    return new Promise(async (resolve, reject) => {

        const pd = {
            "duration": duration,
            "date_from": date_from,
            "date_to": date_to,
            "adults": adults,
            "children": children,
            "type": prod_type
        }

        await api(
            "/booking_avg_cost/", pd,
            "POST",
            (d) => {
                d.json().then((result) => {
                    resolve(result);
                });
            },
            (e) => console.log(e), token, process.env.REACT_APP_STATS_URL
        );
    });
}

export const booking_cost_by_date = async(duration=null, date_from=null, date_to=null, adults=null, children=null, prod_type=null, token) => {
    return new Promise(async (resolve, reject) => {

        const pd = {
            "duration": duration,
            "date_from": date_from,
            "date_to": date_to,
            "adults": adults,
            "children": children,
            "type": prod_type
        }

        await api(
            "/booking_cost_by_date/", pd,
            "POST",
            (d) => {
                d.json().then((result) => {
                    resolve(result);
                });
            },
            (e) => console.log(e), token, process.env.REACT_APP_STATS_URL
        );
    });
}

export const booking_avg_cost_by_date = async(duration=null, date_from=null, date_to=null, adults=null, children=null, prod_type=null, token) => {
    return new Promise(async (resolve, reject) => {

        const pd = {
            "duration": duration,
            "date_from": date_from,
            "date_to": date_to,
            "adults": adults,
            "children": children,
            "type": prod_type=null,
        }

        await api(
            "/booking_avg_cost_by_date/", pd,
            "POST",
            (d) => {
                d.json().then((result) => {
                    resolve(result);
                });
            },
            (e) => console.log(e), token, process.env.REACT_APP_STATS_URL
        );
    });
}

export const booking_count_by_product_date = async(duration=null, date_from=null, date_to=null, adults=null, children=null, prod_type=null, token) => {
    return new Promise(async (resolve, reject) => {

        const pd = {
            "duration": duration,
            "date_from": date_from,
            "date_to": date_to,
            "adults": adults,
            "children": children,
            "type": prod_type,
        }

        await api(
            "/booking_count_by_product_date/", pd,
            "POST",
            (d) => {
                d.json().then((result) => {
                    resolve(result);
                });
            },
            (e) => console.log(e), token, process.env.REACT_APP_STATS_URL
        );
    });
}

export const booking_avg_cost_by_product_date = async(duration=null, date_from=null, date_to=null, adults=null, children=null, prod_type=null, token) => {
    return new Promise(async (resolve, reject) => {

        const pd = {
            "duration": duration,
            "date_from": date_from,
            "date_to": date_to,
            "adults": adults,
            "children": children,
            "type": prod_type,
        }

        await api(
            "/booking_avg_cost_by_product_date/", pd,
            "POST",
            (d) => {
                d.json().then((result) => {
                    resolve(result);
                });
            },
            (e) => console.log(e), token, process.env.REACT_APP_STATS_URL
        );
    });
}

export const booking_count_by_arrival_point_dp_only = async(duration=null, date_from=null, date_to=null, adults=null, children=null, prod_type=null, token) => {
    return new Promise(async (resolve, reject) => {

        const pd = {
            "duration": duration,
            "date_from": date_from,
            "date_to": date_to,
            "adults": adults,
            "children": children,
            "type": prod_type=null,
        }

        await api(
            "/booking_count_by_arrival_point_dp_only/", pd,
            "POST",
            (d) => {
                d.json().then((result) => {
                    resolve(result);
                });
            },
            (e) => console.log(e), token, process.env.REACT_APP_STATS_URL
        );
    });
}