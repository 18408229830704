import dayjs from 'dayjs';


const const_section = (product) => {
    let costs = []
    costs.push(<tr>
        <td>Margin</td>
        <td>{product.margin}</td>
        </tr>);
        costs.push(<tr>
            <td>Provider cost</td>
            <td>{product.provider_price}</td>
            </tr>);
        costs.push(<tr>
            <td>Original cost</td>
            <td>{product.original_price}</td>
            </tr>);
        costs.push(<tr>
            <td>Discount</td>
            <td>{product.discount}</td>
            </tr>);
        costs.push(<tr>
            <td>Cost</td>
            <td>{product.price}</td>
            </tr>);
    return costs;
}

const provider_name = (provider_id) => {

    switch(provider_id){
        case 1:
            return "Flexible Autos"
        case 2:
            return "Jumbo"
        case 3:
            return "Sun Hotels"
        case 4:
            return "Hotel Beds"
        case 5:
            return "You Travel"
        case 6:
            return "Beds With Ease"
        case 7:
            return "Paxport"
        case 8:
            return "Holiday Taxis"
        case 9:
            return "SkiSet"
        case 10:
            return "Alps2Alps"
        case 11:
            return "Jet 2"
        case 12:
            return "TravelTek"
        case 13:
            return "Sun Transfers"
        case 14:
            return "Holiday Extras"      
        default:
            return "N/A"
    }
}

export const room_prod_table = (room_products, accommodation) => {

    let arr = []
    for(const room_product of room_products){

        let booking_com = null;
        if(room_product.booking_component != null){
            booking_com = <tr>
            <td>Booking reference</td>
            <td>{room_product.booking_component.reference}</td>
        </tr>
        }

        let room_check_in = dayjs(room_product.start_date, 'YYYY-MM-DD HH:mm');
        room_check_in = room_check_in.format('DD/MM/YY');

        let pName = provider_name(room_product.provider_id);

        let room_costs = const_section(room_product);
        arr.push(<table>
            <tbody>
                <tr>
                    <td className='title_row' colspan='2'>Accommodation</td>
                </tr>
                {booking_com}
                <tr>
                    <td>Name</td>
                    <td>{accommodation.name}</td>
                </tr>
                <tr>
                    <td>Address</td>
                    <td>{accommodation.address} ({accommodation.lat},{accommodation.long})</td>
                </tr>	
                <tr>
                <td>Destination</td>
                    <td>{accommodation.resort.regions[0].destinations[0].name} {accommodation.resort.regions[0].name} {accommodation.resort.name}</td>
                </tr>	
                <tr>
                    <td>Check in</td>
                    <td>{room_check_in}</td>
                </tr>	
                <tr>
                    <td>Room</td>
                    <td>{room_product.description + ' ' + room_product.board_description}<br/>{""}</td>
                </tr>
                <tr>
                    <td>Supplier</td>
                    <td><br/>{pName}</td>
                </tr>
                {room_costs}
            </tbody>
            </table>)
    }
    return arr;
}

export const flight_prod_table = (flight_product) => {

    let flight_departing = flight_product.departing
    flight_departing = dayjs(flight_departing, 'YYYY-MM-DD HH:mm');
    flight_departing = flight_departing.format('DD/MM/YY HH:mm');

    let flight_arrival = flight_product.arrival
    flight_arrival = dayjs(flight_arrival, 'YYYY-MM-DD HH:mm');
    flight_arrival = flight_arrival.format('DD/MM/YY HH:mm');

    let flight_return_departing = flight_product.return_departing
    flight_return_departing = dayjs(flight_return_departing, 'YYYY-MM-DD HH:mm');
    flight_return_departing = flight_return_departing.format('DD/MM/YY HH:mm');

    let flight_return_arrival = flight_product.return_arrival
    flight_return_arrival = dayjs(flight_return_arrival, 'YYYY-MM-DD HH:mm');
    flight_return_arrival = flight_return_arrival.format('DD/MM/YY HH:mm');
    
    let flight_costs = const_section(flight_product);

    let booking_com = null;
    if(flight_product.booking_component != null){
        booking_com = <tr>
        <td>Booking reference</td>
        <td>{flight_product.booking_component.reference}</td>
    </tr>
    }

    let pName = provider_name(flight_product.provider_id);

    return(<table>
    <tbody>
        <tr>
            <td className='title_row' colspan='2'>Flights</td>
        </tr>
        {booking_com}
        <tr>
            <td>Route</td>
            <td>{flight_product.departure_airport.code.toUpperCase() + '-' + flight_product.arrival_airport.code.toUpperCase()}</td>
        </tr>
        <tr>
            <td>Airline</td>
            <td>{flight_product.airline}</td>
        </tr>
        <tr>
            <td>Outbound flight number</td>
            <td>{flight_product.number}</td>
        </tr>
        <tr>
            <td>Inbound flight number</td>
            <td>{flight_product.return_number}</td>
        </tr>
        <tr>
            <td>Outbound departure</td>
            <td>{flight_departing} - {flight_arrival}</td>
        </tr>
        <tr>
            <td>Inbound departure</td>
            <td>{flight_return_departing} - {flight_return_arrival} </td>
        </tr>
        <tr>
            <td>Supplier</td>
            <td>{pName}</td>
        </tr>
        {flight_costs}
    </tbody>
</table>)
}

export const flight_extra_prod_table = (flight_extras) => {

    let arr = []
    for(const fe of flight_extras){



        let extra_costs = const_section(fe);
        arr.push(<><tr>
                    <td>Description</td>
                    <td>{fe.description}</td>
                </tr>{extra_costs}</>)
                }


    let x = <table>
    <tbody>
        <tr>
            <td className='title_row' colspan='2'>Flight Extra</td>
        </tr>
        {arr}
    </tbody>
    </table>;

    return x;
}

export const transfer_prod_table = (transfer_product) => {

    let transfer_costs = const_section(transfer_product);
    let booking_com = null;
    if(transfer_product.booking_component != null){
        booking_com = <tr>
        <td>Booking reference</td>
        <td>{transfer_product.booking_component.reference}</td>
    </tr>
    }

    let pName = provider_name(transfer_product.provider_id);

    return(<table>
    <tbody>
        <tr>
            <td className='title_row' colSpan='2'>Transfer</td>
        </tr>
        {booking_com}
        <tr>
            <td>Type</td>
            <td>{transfer_product.vehicle_type}</td>
        </tr>
        <tr>
            <td>Description</td>
            <td>{transfer_product.description}</td>
        </tr>
        <tr>
            <td>Supplier</td>
            <td>{pName}</td>
        </tr>
        {transfer_costs}
    </tbody>
    </table>)
}

export const parking_prod_table = (parking_product) => {
    let parking_costs = const_section(parking_product);
    let booking_com = null;
    if(parking_product.booking_component != null){
        booking_com = <tr>
        <td>Booking reference</td>
        <td>{parking_product.booking_component.reference}</td>
    </tr>
    }

    let pName = provider_name(parking_product.provider_id);

    return(<table>
    <tbody>
        <tr>
            <td className='title_row' colSpan='2'>Airport Parking</td>
        </tr>
        {booking_com}
        <tr>
            <td>Description</td>
            <td>{parking_product.description}</td>
        </tr>
        <tr>
            <td>Supplier</td>
            <td>Holiday Extras</td>
        </tr>
        {parking_costs}
    </tbody>
    </table>)
}

export const airport_acc_prod_table = (airport_acc_product) => {
    let prod_costs = const_section(airport_acc_product);
    let booking_com = null;
    if(airport_acc_product.booking_component != null){
        booking_com = <tr>
        <td>Booking reference</td>
        <td>{airport_acc_product.booking_component.reference}</td>
    </tr>
    }

    let pName = provider_name(airport_acc_product.provider_id);

    return(<table>
    <tbody>
        <tr>
            <td className='title_row' colSpan='2'>Airport Accommodation</td>
        </tr>
        {booking_com}
        <tr>
            <td>Description</td>
            <td>{airport_acc_product.description}</td>
        </tr>
        <tr>
            <td>Supplier</td>
            <td>Holiday Extras</td>
        </tr>
        {prod_costs}
    </tbody>
    </table>)
}

export const lounge_prod_table = (lounge_product) => {
    let prod_costs = const_section(lounge_product);
    let booking_com = null;
    if(lounge_product.booking_component != null){
        booking_com = <tr>
        <td>Booking reference</td>
        <td>{lounge_product.booking_component.reference}</td>
    </tr>
    }

    let pName = provider_name(lounge_product.provider_id);

    return(<table>
    <tbody>
        <tr>
            <td className='title_row' colSpan='2'>Lounge</td>
        </tr>
        {booking_com}
        <tr>
            <td>Description</td>
            <td>{lounge_product.description}</td>
        </tr>
        <tr>
            <td>Supplier</td>
            <td>Holiday Extras</td>
        </tr>
        {prod_costs}
    </tbody>
    </table>)
}
export const package_prod_table = (package_product) => {

    let flight_departing = package_product.flight.outbound_departure
    flight_departing = dayjs(flight_departing, 'YYYY-MM-DD HH:mm');
    flight_departing = flight_departing.format('DD/MM/YY HH:mm');

    let flight_arrival = package_product.flight.outbound_arrival
    flight_arrival = dayjs(flight_arrival, 'YYYY-MM-DD HH:mm');
    flight_arrival = flight_arrival.format('DD/MM/YY HH:mm');

    let flight_return_departing = package_product.flight.inbound_departure
    flight_return_departing = dayjs(flight_return_departing, 'YYYY-MM-DD HH:mm');
    flight_return_departing = flight_return_departing.format('DD/MM/YY HH:mm');

    let flight_return_arrival = package_product.flight.inbound_arrival
    flight_return_arrival = dayjs(flight_return_arrival, 'YYYY-MM-DD HH:mm');
    flight_return_arrival = flight_return_arrival.format('DD/MM/YY HH:mm');
    
    let pak_costs = const_section(package_product.rooms[0]);

    let booking_com = null;
    if(package_product.booking_component != null){
        booking_com = <tr>
        <td>Booking reference</td>
        <td>{package_product.booking_component.reference}</td>
    </tr>
    }

    const room_rows = []
    for(const r of package_product.rooms){
        room_rows.push(<tr>
            <td className='title_row' colspan='2'>Room {r.room_number}</td>
        </tr>)
       room_rows.push(<tr>
        <td>Room</td>
        <td>{r.description}</td>
        </tr>)
        room_rows.push(<tr>
        <td>Board</td>
        <td>{r.board_description}</td>
        </tr>)
    }

    return(<table>
    <tbody>
        <tr>
            <td className='title_row' colspan='2'>Package Holiday</td>
        </tr>
        {booking_com}
        <tr>
            <td>Route</td>
            <td>{package_product.flight.departure_point.code.toUpperCase() + '-' + package_product.flight.arrival_airport.code.toUpperCase()}</td>
        </tr>
        <tr>
            <td>Outbound flight number</td>
            <td>{package_product.flight.outbound_number}</td>
        </tr>
        <tr>
            <td>Inbound flight number</td>
            <td>{package_product.flight.inbound_number}</td>
        </tr>
        <tr>
            <td>Outbound departure</td>
            <td>{flight_departing} - {flight_arrival}</td>
        </tr>
        <tr>
            <td>Inbound departure</td>
            <td>{flight_return_departing} - {flight_return_arrival} </td>
        </tr>
        <tr>
            <td>Errata</td>
            <td>{""}</td>
        </tr>
        <tr>
            <td>Accommodation</td>
            <td>{package_product.accommodation.name}</td>
        </tr>
        <tr>
            <td>Address</td>
            <td>{package_product.accommodation.address}</td>
        </tr>
        <tr>
            <td>Destination</td>
            <td>{package_product.accommodation.resort.regions[0].destinations[0].name} {package_product.accommodation.resort.regions[0].name} {package_product.accommodation.resort.name}</td>
        </tr>
        {room_rows}
        {pak_costs}
    </tbody>
</table>)
}

export const supplements_table = (supplements) => {
    const supRows = [];
    for(const x of supplements){
        supRows.push(<tr>
            <td>{x.description}</td>
            <td>£{x.price}</td>
        </tr>)
    }

    return(<table>
        <tbody>
            <tr>
                <td className='title_row' colspan='2'>Supplements</td>
            </tr>
            {supRows}
        </tbody>
    </table>)

}
